html, body {
  margin: 0;
  height: 100%;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#viewport {
  height: 100%;
}

.silent-iframe {
  border-width: 0;
  position: absolute;
  top: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}

/* *, :after, :before {
  box-sizing: border-box;
} */

.sigeco-boton {
  font-family: inherit !important;
  font-size: 0 !important;
  font-weight: 400 !important;
  margin-left: 8px !important;
  min-height: 25px !important;
  min-width: 45px !important;
  padding: 2px 6px !important;
  text-transform: none !important;
}

.sigeco-boton-icon {
  font-size: 0.875rem !important;
  width: 18px !important;
}

.sigeco-contenido {
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
}

.sigeco-panel {
  flex-direction: column;
  display: flex;
  height: calc(100% - 36px);
  position: relative;
}

.sigeco-mask {
  background-color: rgba(0, 0, 0, 0.3);
  height: calc(100% + 90px);
  left: 0;
  position: absolute;
  top: -90px;
  width: 100%;
  z-index: 1200;
}

.sigeco-load {
  background-color: rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  color: #ededed;
  font-size: 0.75rem;
  height: 56px;
  padding: 16px;
  position: absolute;
  text-align: center;
  width: 72px;
  top: calc(50% - 44px);
  left: calc(50% - 52px);
}

table.construccion th {
  font-size: 0.95rem;
  padding: .2em .8em;
  border: 1px solid rgba(20,50,90,.25);
  background: #BFD9FF;
  text-align: center;
}

table.construccion td {
  font-size: 0.725rem;
  border-collapse: collapse;
  
  text-align: center;
}

table.aprobacion table {
  border-collapse: collapse;
}
table.aprobacion th {
  font-size: 13px; 
  border-collapse: collapse;
  background-color: rgba(16,76,167,.25);
  text-align: center;
}

table.aprobacion td {
  font-size: max(1vw, 5px);
  border-collapse: collapse;
  border-color: rgba(16,76,167,.25);
  text-align: center;
}

@media (min-width: 800px){
  .sigeco-boton {
    font-size: 0.75rem !important;
    min-width: 64px !important;
  }

  .sigeco-boton-icon {
    margin-right: 4px !important;
  }
}